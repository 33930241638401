/* eslint-disable react/forbid-prop-types */
/* eslint react/jsx-key: off */
import React from "react";

import {
  Edit,
  DateInput,
  BooleanInput,
  NumberInput,
  TextInput,
  FormTab,
  SaveButton,
  DeleteWithConfirmButton,
  TabbedForm,
  Toolbar,
  useRedirect,
  useNotify,
  useRefresh,
} from "react-admin";

import ECDConfigTitle from "./ECDConfigTitle";

import JsonInput from "../AlembicJsonFields/JsonInput";
import { makeStyles } from "@mui/styles";

import Aside from "./Aside";

const useToolbarStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

/**
 * Custom Toolbar for the Edit form
 *
 * Save with undo, but delete with confirm
 */
const ECDConfigEditToolbar = (props) => {
  const classes = useToolbarStyles();
  return (
    <Toolbar {...props} classes={classes}>
      <SaveButton />
      <DeleteWithConfirmButton redirect="/Container" />
    </Toolbar>
  );
};

const ECDConfigEdit = ({ permissions, ...props }) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();
  const TSMOOTHIE_SIGMA_EVENT_DEFAULT_VALUE = 2.7;

  const onSuccess = (data) => {
    notify(`Changes to ECD Config "${data.id}" saved`);
    redirect("/Container");
    refresh();
  };

  const handleTransform = (data) => {
    const final = data;
    final.global_start_date = new Date(data.global_start_date).toISOString();
    return final;
  };

  return (
    <Edit
      {...props}
      title={<ECDConfigTitle edit={true} />}
      aside={<Aside />}
      transform={handleTransform}
      mutationOptions={{ onSuccess }}
      mutationMode="pessimistic"
    >
      <TabbedForm toolbar={<ECDConfigEditToolbar />}>
        <FormTab label="ecdconfig.form.summary">
          <TextInput source="id" disabled />
          <DateInput source="created_at" disabled />
          <DateInput source="updated_at" disabled />{" "}
          <DateInput
            source="global_start_date"
            label="Start date for ECD"
            defaultValue="2021/12/01"
          />
        </FormTab>
        <FormTab label="ecdconfig.form.subsystems">
          <BooleanInput
            source="enable_ga_events"
            label="Include Google Analytics in ECD"
            defaultValue
          />
          <BooleanInput
            source="enable_aa_events"
            label="Include Adobe Analytics in ECD"
            defaultValue
          />
          <BooleanInput
            source="enable_broadcasts_events"
            label="Include Broadcasts in ECD"
            defaultValue
          />
          <BooleanInput
            source="enable_podcasts_events"
            label="Include Podcasts in ECD"
            defaultValue
          />
          <BooleanInput
            source="enable_social_animal_events"
            label="Include Social Animal in ECD"
            defaultValue
          />
          <BooleanInput
            source="enable_impressions_events"
            label="Include Impression Events in ECD"
            defaultValue
          />
          <BooleanInput
            source="enable_coincidence_events"
            label="Run Concidence Detections"
            defaultValue
          />
        </FormTab>
        <FormTab label="ecdconfig.form.peakconfig">
          <NumberInput
            source="peak_event_min_magnitude"
            label="Peak Detector Min Magnitude (default)"
            fullWidth
          />
          <JsonInput
            source="peak_event_min_magnitude_dict"
            label="Peak Detector Min Magnitude Overrides"
            fullWidth
          />
        </FormTab>
        <FormTab label="ecdconfig.form.tsmoothiesigmaconfig">
          <NumberInput
            source="tsmoothie_sigma"
            label="Tsmoothie Sigma Config (default)"
            fullWidth
            defaultValue={TSMOOTHIE_SIGMA_EVENT_DEFAULT_VALUE}
          />
          <JsonInput
            source="tsmoothie_sigma_event_override"
            label="Tsmoothie Sigma Event Overrides"
            fullWidth
          />
        </FormTab>
        <FormTab label="ecdconfig.form.cdconfig">
          <NumberInput
            source="cd_min_rel_rank_default"
            label="Coincidence detection MRR (default)"
            fullWidth
          />
          <JsonInput
            source="cd_min_rel_rank_dict"
            label="Concidence Detector MRR Overrides"
            fullWidth
          />
        </FormTab>
        <FormTab label="ecdconfig.form.gaconfig">
          <TextInput source="ga_product_name_replace_pattern" fullWidth />
          <TextInput source="ga_item_name_replace_pattern" fullWidth />
          <NumberInput source="ga_min_metric_sum_all_time" fullWidth />
          <NumberInput source="ga_metric_pct_of_total_cutoff" fullWidth />
          <NumberInput source="ga_max_time_series_count" fullWidth />
          <NumberInput source="aa_min_metric_sum_all_time" fullWidth />
          <NumberInput source="aa_metric_pct_of_total_cutoff" fullWidth />
          <NumberInput source="aa_max_time_series_count" fullWidth />
        </FormTab>
        <FormTab label="ecdconfig.form.advanced">
          <NumberInput source="max_duration_days" defaultValue={90} />
          <NumberInput source="peak_win_size" defaultValue={10} />
          <NumberInput source="peak_cutoff_th" defaultValue={0.8} />
          <NumberInput source="mag_base_look_back_days" defaultValue={30} />
          <NumberInput source="min_median_n" defaultValue={5} />
          <NumberInput source="min_daily_initial_group_size" defaultValue={4} />
          <NumberInput source="cd_match_norm_threshold" defaultValue={0.5} />
          <NumberInput
            source="cd_split_threshold_days"
            step={1}
            defaultValue={10000}
          />
          <NumberInput
            source="cd_split_sensitivity"
            step={1}
            defaultValue={3}
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default ECDConfigEdit;
