/* eslint react/jsx-key: off */
import React, { useEffect } from "react";
import {
  useTranslate,
  Create,
  SaveButton,
  Toolbar,
  BooleanInput,
  TabbedForm,
  FormTab,
  NumberInput,
  DateInput,
  TextInput,
} from "react-admin";

import JsonInput from "../AlembicJsonFields/JsonInput";

import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

import { makeStyles } from "@mui/styles";

import Aside from "./Aside";

const useToolbarStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const DETECTED_EVENT_LISTS = [
  "TYPE_BC_RADIO_MENTIONS",
  "TYPE_BC_TV_MENTIONS_WITH_VIEWERSHIP",
  "TYPE_BC_TV_MENTIONS",
  "TYPE_BC_TV_VIEWERSHIP",
  "TYPE_PC_SUMMARY_MENTIONS",
  "TYPE_PC_SUMMARY_MENTIONS_WITH_AUDIENCE",
  "TYPE_PC_SUMMARY_AUDIENCE",
  "TYPE_PC_TRANSCRIPT_MENTIONS",
  "TYPE_PC_TRANSCRIPT_MENTIONS_WITH_AUDIENCE",
  "TYPE_PC_TRANSCRIPT_AUDIENCE",
  "TYPE_GA_CONVERSIONS_BY_GOAL",
  "TYPE_GA_QUANTITY_BY_PRODUCT",
  "TYPE_GA_REVENUE_BY_PRODUCT",
  "TYPE_GA_REVENUE_BY_SOURCE_MEDIUM",
  "TYPE_GA_REVENUE_PER_TRANSACTION",
  "TYPE_GA_TRANSACTIONS_PER_SESSION",
  "TYPE_GA_USERS_BY_AD_MATCHED_QUERY",
  "TYPE_GA_USERS_BY_PAGE_PATH",
  "TYPE_GA_USERS_BY_SEARCH_KEYWORD",
  "TYPE_GA_USERS_BY_SOURCE",
  "TYPE_GA_USERS_BY_CHANNEL_GROUPING",
  "TYPE_GA_USERS_BY_CAMPAIGN",
  "TYPE_GA4_QUANTITY_BY_ITEM_NAME",
  "TYPE_GA4_REVENUE_BY_ITEM_NAME",
  "TYPE_GA4_REVENUE_BY_SESSION_SOURCE",
  "TYPE_GA4_TOTAL_USERS_BY_FIRST_USER_GOOGLE_ADS_QUERY",
  "TYPE_GA4_TOTAL_USERS_BY_PAGE_PATH",
  "TYPE_GA4_TOTAL_USERS_BY_SESSION_GOOGLE_ADS_QUERY",
  "TYPE_GA4_TOTAL_USERS_BY_SESSION_MANUAL_TERM",
  "TYPE_GA4_TOTAL_USERS_BY_SOURCE",
  "TYPE_GA4_TRANSACTIONS_PER_PURCHASER",
  "TYPE_GA4_TOTAL_USERS_BY_DEFAULT_CHANNEL_GROUPING",
  "TYPE_GA4_TOTAL_USERS_BY_SESSION_CAMPAIGN_NAME",
  "TYPE_GA4_CONVERSIONS_BY_GOAL",
  "TYPE_AA_USERS_BY_PAGE_PATH",
  "TYPE_AA_USERS_BY_SEARCH_KEYWORD",
  "TYPE_AA_USERS_BY_PAID_SEARCH",
  "TYPE_AA_USERS_BY_SOURCE",
  "TYPE_AA_USERS_BY_ON_SITE_SEARCH",
  "TYPE_AA_USERS_BY_CAMPAIGN",
  "TYPE_AA_USERS_BY_MARKETING_CHANNEL",
  "TYPE_AA_INTERACTIONS_BY_EVENT",
  "TYPE_AA_CONVERSIONS_BY_EVENT",
  "TYPE_IMPRESSIONS_DAILY_POST_INITIAL",
  "TYPE_IMPRESSIONS_LINKTOKEN_RESURGE",
  "TYPE_IMPRESSIONS_POST_RESURGE",
  "TYPE_IMPRESSIONS_SINGLE_POST_INITIAL",
  "TYPE_SOCIAL_ANIMAL_ARTICLES",
  "TYPE_SOCIAL_ANIMAL_SHARES",
  "TYPE_AA_REVENUE_BY_SOURCE",
  "TYPE_AA_REVENUE_BY_CAMPAIGN",
  "TYPE_AA_REVENUE_BY_CHANNEL",
  "TYPE_AA_REVENUE_BY_FARE_TYPE",
  "TYPE_AA_TRANSACTIONS_BY_CAMPAIGN",
  "TYPE_AA_TRANSACTIONS_BY_CHANNEL",
  "TYPE_AA_TRANSACTIONS_BY_FARE_TYPE",
  "TYPE_AA_QUANTITY_BY_CAMPAIGN",
  "TYPE_AA_QUANTITY_BY_CHANNEL",
  "TYPE_AA_QUANTITY_BY_FARE_TYPE",
  "TYPE_AA_SKYMILES_USED_BY_CAMPAIGN",
  "TYPE_AA_SKYMILES_USED_BY_CHANNEL",
  "TYPE_AA_SKYMILES_USED_BY_FARE_TYPE",
  "TYPE_NC_BC_TV_NARRATIVE_OCCURRENCES",
  "TYPE_NC_BC_RADIO_NARRATIVE_OCCURRENCES",
  "TYPE_NC_PC_SUMMARY_NARRATIVE_OCCURRENCES",
  "TYPE_NC_PC_TRANSCRIPT_NARRATIVE_OCCURRENCES",
  "TYPE_NC_BC_TV_NARRATIVE_RATINGS_LOCAL",
  "TYPE_NC_BC_TV_NARRATIVE_RATINGS_NATIONAL",
  "TYPE_NC_PC_TRANSCRIPT_NARRATIVE_RATINGS",
  "TYPE_SB_REVENUE_BY_STORE",
  "TYPE_SB_TRANSACTIONS_BY_STORE",
  "TYPE_SB_REVENUE_BY_CHANNEL",
  "TYPE_SB_TRANSACTIONS_BY_CHANNEL",
  "TYPE_SB_REVENUE_BY_PRODUCT",
  "TYPE_SB_QUANTITY_BY_PRODUCT",
  "TYPE_SB_REVENUE_BY_LOYALTY_PROGRAM",
  "TYPE_SB_QUANTITY_BY_LOYALTY_PROGRAM",
  "TYPE_SB_REVENUE_BY_DISCOUNT1",
  "TYPE_SB_QUANTITY_BY_DISCOUNT1",
  "TYPE_SB_REVENUE_BY_DISCOUNT2",
  "TYPE_SB_QUANTITY_BY_DISCOUNT2",
  "TYPE_SP_FOOTTRAFFIC_BY_STORE",
  "TYPE_SOCIAL_ANIMAL_ARTICLES",
  "TYPE_SOCIAL_ANIMAL_SHARES",
  "TYPE_SF_LEADS_BY_PROGRAM",
  "TYPE_SF_LEADS_BY_SOURCE",
  "TYPE_SF_OPPORTUNITIES_DIRECT_CREATED_BY_CAMPAIGN",
  "TYPE_SF_OPPORTUNITIES_FROM_LEADS_BY_CAMPAIGN",
  "TYPE_SF_OPPORTUNITIES_BY_SOURCE",
  "TYPE_SF_REVENUE_BY_SOURCE",
];

// cdMinRelRank config.
const CD_MIN_REL_RANK_DEFAULT_VALUE = 0;
const cdMinRelRankDefaults = DETECTED_EVENT_LISTS.reduce(function (map, obj) {
  map[obj] = CD_MIN_REL_RANK_DEFAULT_VALUE;
  return map;
}, {});

// tsmoothieSigmaEvent config.
const TSMOOTHIE_SIGMA_EVENT_DEFAULT_VALUE = 2.7;
const tsmoothieSigmaEventOverrides = {
  NC_BC_TV_NARRATIVE_OCCURRENCES: 2.01,
  NC_BC_RADIO_NARRATIVE_OCCURRENCES: 2.01,
  NC_PC_SUMMARY_NARRATIVE_OCCURRENCES: 2.01,
  NC_PC_TRANSCRIPT_NARRATIVE_OCCURRENCES: 2.01,
  NC_BC_TV_NARRATIVE_RATINGS_LOCAL: 2.01,
  NC_PC_TRANSCRIPT_NARRATIVE_RATINGS: 2.01,
  NC_BC_TV_NARRATIVE_RATINGS_NATIONAL: 2.01,
};
// we need to remvove TYPE_ from the event names for tsmoothie
const TSMOOTHIE_DETECTED_EVENT_LISTS = DETECTED_EVENT_LISTS.map((de) => {
  return de.slice(5);
});
const tsmoothieSigmaEventDefaults = TSMOOTHIE_DETECTED_EVENT_LISTS.reduce(
  function (map, obj) {
    const overrides = new Map(Object.entries(tsmoothieSigmaEventOverrides));
    if (overrides.has(obj)) {
      map[obj] = overrides.get(obj);
    } else {
      map[obj] = TSMOOTHIE_SIGMA_EVENT_DEFAULT_VALUE;
    }
    return map;
  },
  {}
);

// peakEventMinMagnitude config.
const PEAK_EVENT_MIN_MAGNITUDE_DEFAULT_VALUE = 4;
const peakEventMinMagnitudeOverrides = {
  TYPE_GA_USERS_BY_AD_MATCHED_QUERY: 10,
  TYPE_GA_USERS_BY_PAGE_PATH: 100,
  TYPE_GA_USERS_BY_SEARCH_KEYWORD: 10,
  TYPE_GA_USERS_BY_SOURCE: 20,
  TYPE_GA_USERS_BY_CHANNEL_GROUPING: 20,
  TYPE_GA_USERS_BY_CAMPAIGN: 20,
  TYPE_GA4_REVENUE_BY_ITEM_NAME: 20,
  TYPE_GA4_REVENUE_BY_SESSION_SOURCE: 20,
  TYPE_GA4_TOTAL_USERS_BY_FIRST_USER_GOOGLE_ADS_QUERY: 10,
  TYPE_GA4_TOTAL_USERS_BY_PAGE_PATH: 100,
  TYPE_GA4_TOTAL_USERS_BY_SESSION_GOOGLE_ADS_QUERY: 10,
  TYPE_GA4_TOTAL_USERS_BY_SESSION_MANUAL_TERM: 10,
  TYPE_GA4_TOTAL_USERS_BY_SOURCE: 20,
  TYPE_GA4_TOTAL_USERS_BY_DEFAULT_CHANNEL_GROUPING: 20,
  TYPE_GA4_TOTAL_USERS_BY_SESSION_CAMPAIGN_NAME: 20,
  TYPE_GA4_TRANSACTIONS_PER_PURCHASER: 20,
  TYPE_AA_USERS_BY_PAGE_PATH: 100,
  TYPE_AA_USERS_BY_SEARCH_KEYWORD: 10,
  TYPE_AA_USERS_BY_PAID_SEARCH: 10,
  TYPE_AA_USERS_BY_SOURCE: 20,
  TYPE_IMPRESSIONS_DAILY_POST_INITIAL: 90,
  TYPE_IMPRESSIONS_LINKTOKEN_RESURGE: 80,
  TYPE_IMPRESSIONS_POST_RESURGE: 95,
  TYPE_IMPRESSIONS_SINGLE_POST_INITIAL: 90,
  TYPE_NC_BC_TV_NARRATIVE_OCCURRENCES: 10,
  TYPE_NC_BC_RADIO_NARRATIVE_OCCURRENCES: 10,
  TYPE_NC_PC_SUMMARY_NARRATIVE_OCCURRENCES: 1,
  TYPE_NC_PC_TRANSCRIPT_NARRATIVE_OCCURRENCES: 1,
};
const peakEventMinMagnitudeDefaults = DETECTED_EVENT_LISTS.reduce(function (
  map,
  obj
) {
  const overrides = new Map(Object.entries(peakEventMinMagnitudeOverrides));
  if (overrides.has(obj)) {
    map[obj] = overrides.get(obj);
  } else {
    map[obj] = PEAK_EVENT_MIN_MAGNITUDE_DEFAULT_VALUE;
  }
  return map;
},
{});

const ECDConfigCreateToolbar = (props) => {
  const classes = useToolbarStyles();
  return (
    <Toolbar {...props} classes={classes}>
      <SaveButton />
    </Toolbar>
  );
};

const ECDConfigCreate = ({ children, ...props }) => {
  const translate = useTranslate();
  const location = useLocation();
  const navigate = useNavigate();

  // this is a set-one and lock sort of deal.
  const [containerId, setContainerId] = React.useState(undefined);
  const [containerName, setContainerName] = React.useState(undefined);

  useEffect(() => {
    if (containerId == undefined) {
      if (location.state && location.state.record) {
        setContainerId(
          location.state && location.state.record
            ? location.state.record.container_id
            : undefined
        );

        setContainerName(
          location.state && location.state.record
            ? location.state.record.container_name
            : undefined
        );
      } else {
        console.log("entered create without container state. ejecting");

        navigate("/Container");
      }
    }
  }, [location]);

  const redirect =
    containerId !== null ? `/Container/${containerId}/show` : false;

  const handleTransform = (data) => {
    const final = data;
    final.container_id = parseInt(containerId, 10);
    final.global_start_date = new Date(data.global_start_date).toISOString();
    return final;
  };

  return (
    <Create
      {...props}
      aside={<Aside />}
      title={translate("ecdconfig.create.title", {
        container_id: containerId,
        container_name: containerName,
      })}
      redirect={redirect}
      record={{ container_id: parseInt(containerId, 10) }}
      transform={handleTransform}
    >
      <TabbedForm toolbar={<ECDConfigCreateToolbar />} redirect={redirect}>
        <FormTab label="ecdconfig.form.summary">
          <NumberInput
            source="container_id"
            defaultValue={containerId}
            disabled
          />
          <DateInput
            source="global_start_date"
            label="Start date for ECD"
            defaultValue="2021/12/01"
          />
        </FormTab>
        <FormTab label="ecdconfig.form.subsystems">
          <BooleanInput
            source="enable_ga_events"
            label="Include Google Analytics in ECD"
            defaultValue
          />
          <BooleanInput
            source="enable_aa_events"
            label="Include Adobe Analytics in ECD"
            defaultValue
          />
          <BooleanInput
            source="enable_broadcasts_events"
            label="Include Broadcasts in ECD"
            defaultValue
          />
          <BooleanInput
            source="enable_podcasts_events"
            label="Include Podcasts in ECD"
            defaultValue
          />
          <BooleanInput
            source="enable_social_animal_events"
            label="Include Social Animal in ECD"
            defaultValue
          />
          <BooleanInput
            source="enable_impressions_events"
            label="Include Impression Events in ECD"
            defaultValue
          />
          <BooleanInput
            source="enable_coincidence_events"
            label="Run Concidence Detections"
            defaultValue
          />
        </FormTab>
        <FormTab label="ecdconfig.form.peakconfig">
          <NumberInput
            source="peak_event_min_magnitude"
            defaultValue={PEAK_EVENT_MIN_MAGNITUDE_DEFAULT_VALUE}
            label="Peak Detector Min Magnitude (default)"
          />
          <JsonInput
            source="peak_event_min_magnitude_dict"
            label="Peak Detector Min Magnitude Overrides"
            defaultValue={peakEventMinMagnitudeDefaults}
          />
        </FormTab>
        <FormTab label="ecdconfig.form.tsmoothiesigmaconfig">
          <NumberInput
            source="tsmoothie_sigma"
            label="Tsmoothie Sigma Config"
            defaultValue={TSMOOTHIE_SIGMA_EVENT_DEFAULT_VALUE}
          />
          <JsonInput
            source="tsmoothie_sigma_event_override"
            label="Tsmoothie Sigma Event Overrides"
            defaultValue={tsmoothieSigmaEventDefaults}
          />
        </FormTab>
        <FormTab label="ecdconfig.form.cdconfig">
          <NumberInput
            source="cd_min_rel_rank_default"
            defaultValue={CD_MIN_REL_RANK_DEFAULT_VALUE}
            label="Coincidence detection MRR (default)"
          />
          <JsonInput
            source="cd_min_rel_rank_dict"
            label="Concidence Detector MRR Overrides"
            defaultValue={cdMinRelRankDefaults}
          />
        </FormTab>
        <FormTab label="ecdconfig.form.gaconfig">
          <TextInput source="ga_product_name_replace_pattern" />
          <TextInput source="ga_item_name_replace_pattern" />
          <NumberInput
            source="ga_min_metric_sum_all_time"
            defaultValue={10.0}
          />
          <NumberInput
            source="ga_metric_pct_of_total_cutoff"
            defaultValue={90.0}
          />
          <NumberInput
            source="ga_max_time_series_count"
            defaultValue={10000.0}
          />
          <NumberInput
            source="aa_min_metric_sum_all_time"
            defaultValue={10.0}
          />
          <NumberInput
            source="aa_metric_pct_of_total_cutoff"
            defaultValue={90.0}
          />
          <NumberInput
            source="aa_max_time_series_count"
            defaultValue={10000.0}
          />
        </FormTab>
        <FormTab label="ecdconfig.form.advanced">
          <NumberInput source="max_duration_days" defaultValue={90} />
          <NumberInput source="peak_win_size" defaultValue={10} />
          <NumberInput source="peak_cutoff_th" defaultValue={0.8} />
          <NumberInput source="mag_base_look_back_days" defaultValue={30} />
          <NumberInput source="min_median_n" defaultValue={5} />
          <NumberInput source="min_daily_initial_group_size" defaultValue={4} />
          <NumberInput source="cd_match_norm_threshold" defaultValue={0.5} />
          <NumberInput
            source="cd_split_threshold_days"
            step={1}
            defaultValue={10000}
          />
          <NumberInput
            source="cd_split_sensitivity"
            step={1}
            defaultValue={3}
          />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default ECDConfigCreate;
